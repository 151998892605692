import gsap from "gsap";
const bar = document.querySelector(".loading__bar--inner");
const counter = document.querySelector(".loading__counter--number")
const logoSrc = document.getElementsByClassName("loading__image")[0].src
const h = document.getElementsByClassName("header")[0]
h.style.visibility="hidden"



//SingletonPattern for static Vars which are filled by intstantiating

class Logo
  {
    #logoSrc
    constructor(src){
        if (Logo._instance) {
            return Logo._instance
          }
          this.logoSrc=src
          Logo._instance = this;
        }
    getSrc() {
        return this.logoSrc
    }
  }

const logo = new Logo(logoSrc)
let c = 0;
 let barInterval = setInterval(()=>{
    bar.style.width = c + "%";
    counter.innerText = c + "%";
    c++;
    if(c > 100){
        clearInterval(barInterval)
        gsap.to(".loading__bar",{
            duration: 5,
            rotate: "95deg",
            left: "1000%",
        });
        
        gsap.to(".loading__text, loading__counter",{
            duration: 5,
            rotate: "95deg",
            right: "1000%",
        });

        gsap.to(".loading__box",{
            duration: 1,
            delay: 1,
            height: "370px",
            borderRadius: "50%",
        });
        gsap.to(".loading__image",{
            duration: 5,
            opacity: 1,
            rotate: "360deg",
        });
        gsap.to(".loading__box",{
            delay: 2,
            border: "none",
        });
        gsap.to(".loading",{
            duration: 2,
            delay: 2,
            background:"transparent",
            opacity: 0.5,
            zIndex: 1,
        });
    }
 },30)
 

const endFunction = () => {
const header = document.getElementsByClassName("header")[0]
header.style.visibility="visible"
const imageContainer = document.getElementsByClassName("image_container" )[0]
imageContainer.style.visibility="visible"

}


 setTimeout(endFunction,6000)



 let images = document.getElementsByClassName("image");
for (let i = 0; i < images.length; i++) {
    images[i].addEventListener("mouseover", showFullShape);
    images[i].addEventListener("mouseout", normalShape);
}

function showFullShape(e) {
    let elem = e.target;
    let loadingImage = document.getElementsByClassName("loading__image")[0]
    const src = elem.src
    loadingImage.src = src
}

function normalShape(e) {
    let loading = document.getElementsByClassName("loading__image")[0]
    loading.src = logo.getSrc()
}




    
